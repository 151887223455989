import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ble_connected: false,
    currentDirectory: ''
  },
  mutations: {
    setConnected(state, status) {
      state.ble_connected = status;
    },
    setCurrentDirectory(state, path) {
      state.currentDirectory = path;
    }
  }
});