<template>
    <div>
      <div class="search-bar">
        <input v-model="searchQuery" placeholder="Search name or series" />
        <select v-model="selectedSeries">
          <option value="">All Series</option>
          <option v-for="series in uniqueSeries" :key="series" :value="series">{{ series }}</option>
        </select>
        <select v-model="selectedCharacter">
          <option value="">All Characters</option>
          <option v-for="character in uniqueCharacters" :key="character" :value="character">{{ character }}</option>
        </select>
      </div>
      <div class="amiibo-list">
        <div class="amiibo-card" v-for="amiibo in filteredAmiibos" :key="amiibo.head + '-' + amiibo.tail">
          <img :src="amiibo.image" alt="Amiibo Image" class="amiibo-image"/>
          <div class="amiibo-details">
            <h2>{{ amiibo.name }}</h2>
            <!-- <p><strong>Amiibo Series:</strong> {{ amiibo.amiiboSeries }}</p> -->
            <!-- <p><strong>Character:</strong> {{ amiibo.character }}</p> -->
            <p><strong>Game Series:</strong> {{ amiibo.gameSeries }}</p>
            <!-- <p><strong>Head:</strong> {{ amiibo.head }}</p> -->
          </div>
          <!-- <div class="amiibo-actions">
            <i class="el-icon-download amiibo-download icon-connected" @click="on_download(amiibo.head, amiibo.tail, amiibo.name)"></i>
            <i class="el-icon-connection icon-connected" v-if="ble_connected" @click="on_transfer(amiibo.head, amiibo.tail, amiibo.name)"></i>
            <i class="el-icon-connection icon-disconnected" v-else @click="on_transfer(amiibo.head, amiibo.tail, amiibo.name)"></i>
          </div> -->
        </div>
      </div>

      <el-dialog title="Transfer Amiibo Bin to Device" :visible.sync="transfer_diag_visible" width="30%" :before-close="on_transfer_diag_close">
        <div>
          Send Amiibo Bin to Local Device Directory: <span style="font-style: italic; font-weight: bold">{{ currentDirectory }} </span> ?
        </div>
        <div>
          <el-button size="small" v-show="sendShow" @click="on_start_download">Send</el-button>
        </div>
        <div v-if="downloading" class="progress-bar">
          <span class="title">Preparing for bin:</span><el-progress :percentage="downloadingProgress/100" :status="downloadingStatus"></el-progress>
        </div>
        <div v-if="transferring || transferCompleted" class="progress-bar">
          <span class="title">Transfering to Device:</span><el-progress :percentage="transferProgress/100" :status="transferStatus"></el-progress>
        </div>
      </el-dialog>

      <el-dialog title="Connect to Device first" :visible.sync="connect_diag_visible" width="30%">
        <p>Please connect your device first, click "Local Amiibo" Tab</p>
        <p>Then click "connect" button on top right</p>
      </el-dialog>

      <el-dialog title="Choose to directory first" :visible.sync="choose_dir_diag_visible" width="30%">
        <p>Please connect your device first, click "Local Amiibo" Tab</p>
        <p>Then enter one of your directory</p>
      </el-dialog>

    </div>
  </template>
  
  <script>
import { sharedEventDispatcher } from "../lib/event";
import apiClient from "@/lib/axios";
import store from '@/store';

  export default {
    data() {
      return {
        amiibos: [],
        searchQuery: '',
        selectedSeries: '',
        selectedCharacter: '',
        connected: false,
        transfer_diag_visible: false,
        connect_diag_visible: false,
        choose_dir_diag_visible: false,
        downloading: false,
        transferring: false,
        downloadingProgress: 0,
        downloadingStatus: 'active',
        transferProgress: 0,
        transferCompleted: false,
        transferStatus: 'active',
        sendShow: true
      };
    },
    created() {
      this.fetchAmiibos();
    },
    computed: {
      uniqueSeries() {
        return [...new Set(this.amiibos.map(amiibo => amiibo.amiiboSeries))];
      },
      uniqueCharacters() {
        return [...new Set(this.amiibos.map(amiibo => amiibo.character))];
      },
      filteredAmiibos() {
        return this.amiibos.filter(amiibo => {
          return (
            (
              !this.searchQuery 
              || amiibo.name.toLowerCase().includes(this.searchQuery.toLowerCase())
              || amiibo.gameSeries.toLowerCase().includes(this.searchQuery.toLowerCase())
            ) 
            &&
              (!this.selectedSeries || amiibo.amiiboSeries === this.selectedSeries) 
            &&
              (!this.selectedCharacter || amiibo.character === this.selectedCharacter)
          );
        });
      },
      ble_connected() {
        return store.state.ble_connected;
      },
      currentDirectory() {
        return store.state.currentDirectory;
      }
    },
    methods: {
      async fetchAmiibos() {
        try {
          const response = await fetch('https://amiiboapi.com/api/amiibo/');
          const data = await response.json();
          this.amiibos = data.amiibo;
        } catch (error) {
          console.error("Error fetching amiibos:", error);
        }
      },
      async on_download(head, tail, name) {
        console.log('Downloaded', head, tail);
        try {
          const response = await apiClient.get(`/api/download`, {
            params: {
              head,
              tail,
              name
            },
            responseType: 'blob', // 重要，确保接收到的是文件的二进制数据
          });

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}.bin`); // 设置下载文件的名字
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error('Error downloading the file', error);
        }
      },
      on_transfer(head, tail, name) {
        console.log(head, tail, name);
        if (!this.ble_connected) {
          this.connect_diag_visible = true;
        } else {
          if (this.currentDirectory === '') {
            this.choose_dir_diag_visible = true;
          } else {
            this.transfer_diag_visible = true;
            this.sendShow = true;
          }
        }
      },
  
      on_ble_connected() {
        this.connected = true
      },
      on_ble_disconnected() {
        this.connected = false
      },
      on_ble_connect_error() {
        this.connected = false
      },
      on_start_download() {
        this.sendShow = false;
        this.downloading = true;
        this.transferStatus = 'active';
        // 模拟下载过程
        this.downloadProgress = 50; // 假设下载进度为50%
        setTimeout(() => {
          this.downloading = false;
          this.downloadingProgress = 100;
          this.startTransfer();
        }, 2000); // 假设下载需要2秒
      },
      startTransfer() {
        this.transferring = true;
        // 模拟传输过程
        this.transferProgress = 0;
        const interval = setInterval(() => {
          if (this.transferProgress < 100) {
            this.transferProgress += 10;
          } else {
            clearInterval(interval);
            this.transferring = false;
            this.transferCompleted = true;
            this.transferStatus = 'success';
            // alert('Transfer completed.');
          }
        }, 1000);
      },

      on_transfer_diag_close(done){
        if (this.downloading || this.transferring) {
          this.$confirm('Confirm to stop tranfer', 'Transfer Stop', {
            confirmButtonText: 'Stop',
            cancelButtonText: 'Cancel'
          }).then( () => {
            done();
            this.downloading = false;
            this.transferring = false;

          }).catch(() => {
            this.downloading = false;
            this.transferring = false;

          })
        } else {
          done()
        }
      }
    },
    mounted() {
      var dispatcher = sharedEventDispatcher();
      dispatcher.addListener("ble_connected", this.on_ble_connected);
      dispatcher.addListener("ble_disconnected", this.on_ble_disconnected);
      dispatcher.addListener("ble_connect_error", this.on_ble_connect_error);
      },
    };
  </script>
  
  <style scoped>
  .search-bar {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .search-bar input,
  .search-bar select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .amiibo-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
  }
  
  .amiibo-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 200px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .amiibo-image {
    /* width: 80px; */
    max-width: 180px;
    height: 180px;
    object-fit: fill;
    /* border-radius: 50%; */
  }
  
  .amiibo-details {
    margin-top: 12px;
  }
  .amiibo-actions {
    border: 1px solid #FF00FF;
    border-radius: 8px
  }
  .amiibo-actions i{
    cursor: pointer;
    margin: 2px 10px;
    font-size: 18px;
  }
  .icon-connected {
    color: #FF00FF;
  }
  .icon-disconnected {
    color: gray;
  }

  .progress-bar .title{
    font-size: 10px;
  }

  progress{
    margin-left: 5px;
  }

  </style>
  