import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import i18n from './i18n';
import store from './store';
import VueCookie from 'vue-cookie';

// Vue.use(ElementUI);

Vue.use(VueCookie);

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  render: h => h(App),
}).$mount('#app');