<template>
  <div id="app">
    <el-container>
      <el-header>
        <div class="header-content">
          <!-- 标题居中 -->
          <div class="logo">
            <h1 class="logo-name">Maleen</h1>
            <img :src="logo" alt="logo"/>
          </div>
          <!-- 语言切换按钮 -->
          <!-- <el-dropdown trigger="click" class="international" @command="handle_set_language">
            <div>
              <el-button size="mini" type="primary" icon="el-icon-setting" @click="dialogVisible = true" >{{ $t('lang.choose') }}</el-button>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item Enabled="language==='zh_CN'" command="zh_CN" divided>
                {{ $t('lang.zhcn') }}
              </el-dropdown-item>
              <el-dropdown-item Enabled="language==='zh_TW'" command="zh_TW" divided>
                {{ $t('lang.zhtw') }}
              </el-dropdown-item>
              <el-dropdown-item Enabled="language==='en'" command="en" divided>
                {{ $t('lang.en') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </el-header>
      <el-row>
        <el-main>
          <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="Instructions" name="instructs">
                <UserInstructions />
            </el-tab-pane>
        
            <el-tab-pane label="Device" name="local">
              <!-- 使用 slot="label" 来放置图标和文本 -->
              <span slot="label">
                <i class="el-icon-connection icon-connected" v-if="ble_connected"></i>
                <i class="el-icon-connection icon-disconnected" v-else></i>
                Device
              </span> 
              <LocalAmiibos/>
          
            </el-tab-pane>

            <el-tab-pane label="Libirary" name="online">
              <OnlineAmiibos />
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-row>
    </el-container>
  </div>
</template>

<script>
import { TabPane, Tabs, Container, Header, Button, Main } from 'element-ui';
import LocalAmiibos from './components/LocalAmiibos.vue';
import OnlineAmiibos from './components/OnlineAmiibos.vue';
import UserInstructions from './components/UserInstructions.vue';
import logo from '@/assets/logo.png';
import store from './store';

import { sharedEventDispatcher } from "./lib/event"

export default {
  components: {
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [Container.name]: Container,
    [Header.name]: Header,
    [Button.name]: Button,
    [Main.name]: Main,
    LocalAmiibos,
    OnlineAmiibos,
    UserInstructions
  },
  data() {
    return {
      activeTab: 'instructs',
      language: 'en',
      connected: false,
      logo: logo
    };
  },
  computed: {
    ble_connected() {
      return store.state.ble_connected;
    }
  },
  methods: {
    switchLanguage() {
      // 切换语言逻辑
    },
    linkBluetooth() {
      // 蓝牙链接逻辑
    },
    handleTabClick() {
      // 可以在这里添加点击Tab时的逻辑
    },
    handle_set_language(lang) {
      this.$i18n.locale = lang;
      this.$cookie.set("lang", lang, 60*60*24*365)
    },
    on_ble_connected() {
      this.connected = true
    },
    on_ble_disconnected() {
      this.connected = false
    },
    on_ble_connect_error() {
      this.connected = false
    },
  },
  mounted() {
    var dispatcher = sharedEventDispatcher();
    dispatcher.addListener("ble_connected", this.on_ble_connected);
    dispatcher.addListener("ble_disconnected", this.on_ble_disconnected);
    dispatcher.addListener("ble_connect_error", this.on_ble_connect_error);

    // proto.init();

    var lang = this.$cookie.get("lang");
    if (!lang) {
      lang = "en"
    }

    this.language = lang;
    this.$i18n.locale = lang;
    this.connBtnText = `${this.$t("conn.connect")}`;
  }
}
</script>

<style>
html, body {
    /* font-family: 'Source Sans Pro', sans-serif; 设置默认字体为Source Sans Pro */
    font-family: 'Oswald', sans-serif; /* 设置默认字体为Oswald */

    font-size: 14px; /* 设置默认字体大小 */
  }

#app {
  width: 960px;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', 'Source Sans Pro', sans-serif; /* 设置默认字体为Oswald */
}
h1 {
  font-size: 1.8em;
}
h2 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.2em;
}

p, li, a {
  font-family: 'Oswald', 'Source Sans Pro', sans-serif; /* 设置默认字体为Oswald */
}

.action-left {
  float: left;
}

.action-right {
  float: right;
}

.header-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.logo {
  vertical-align: middle;
  float: right;
}

.logo h1{
  display: inline;
  color: #FF00FF;
  vertical-align: middle;
  font-size: 2em;
}
.logo img {
  height: 25px;
  vertical-align: middle;
  transform: rotate(20deg);
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.tab-icon {
  margin-right: 5px; /* 与文本保持一定间距 */
  font-size: 14px; /* 根据需要调整图标大小 */
}

.icon-connected {
  color: #409eff; /* 连接成功时的蓝色 */
}

.icon-disconnected {
  color: #c0c4cc; /* 未连接或连接失败时的灰色 */
}

</style>