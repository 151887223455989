<template>
  <el-container>
    <el-header>
      <!-- <h1>Instructions for User</h1> -->
    </el-header>
    <el-container>
      <el-aside width="200px" class="fixed-aside" id="instruct-navi">
        <nav class="sticky-nav">
          <ul>
            <li><a href="#specialfeatures">Special Features</a></li>
            <li><a href="#howtouse">How to Use</a></li>
            <li><a href="#makeamiibos">Make amiibos</a></li>
            <li><a href="#chooseapp">App to Choose</a></li>
            <li><a href="#keyandbinfile">Encryption key and bin file</a></li>
            <li><a href="#blefiletransfer">BLE File Transfer</a></li>
            <li><a href="#firmwareupgrade">OTA Firmware Upgrade</a></li>
            <li><a href="#acknowledgments">Acknowledgments</a></li>
          </ul>
        </nav>
      </el-aside>
      <el-main>
        <div id="manual">
          <p>Thank you for purchasing our smart NFC simulator. It can store up to 2000 NTAG215 type tags and can be used like the original amiibo figures or cards. There’re over 700 built-in tags in the simulator such as complete series of ACNH and Zelda. You can choose whatever the bin file you want and scan it directly on your Switch to enhance your gaming experience. Please feel free to contact us if you have ny inquiries regarding our product.</p>
          <a href="/files/UserManual.pdf" download>Download Instruction in PDF</a>
          <h1 id="specialfeatures">Sepcial Features</h1>
          <ul>
            <li>Unlimited read and write with up to 2000 tags capacity. You can delete or add newest released tags anytime</li>
            <li>Rechargeable battery. Low power consumption Bluetooth chip, ultra-long standby.</li>
            <li>Compatible with multiple APPs. Upload data via Bluetooth, very efficient and convenient.</li>
            <li>OLED screen upgrade gives you a higher resolution and better experience</li>
          </ul>

          <h1 id="howtouse">How to Use</h1>
          <img :src="require('@/assets/images/howtouse.jpg')" alt="" />
          <ul>
            <li>1. Press down the button to switch on the device.</li>
            <li>2. Choose "Amiibo Database"</li>
            <li><img :src="require('@/assets/images/使用内置数据1.png')"></li>
            <li>3. Choose "Browser"</li>
            <li><img :src="require('@/assets/images/使用内置数据2.png')"></li>
            <li>4. Select a game</li>
            <li><img :src="require('@/assets/images/使用内置数据3.png')"></li>
            <li>5. Choose a .BIN file</li>
            <li><img :src="require('@/assets/images/使用内置数据4.png')"></li>
            <li>6. Enter the selected .BIN file. Stay on this page, enable the amiibo function, place the back of the device on the Joy-Con's right analog stick, or the center of the Pro Controller. It may take about 1-3 seconds to read the data. The NFC signal strength is significantly weaker on the screen side.</li>
            <li><img :src="require('@/assets/images/使用内置数据5.png')"></li>
          </ul>

          <h1 id="makeamiibos">Make amiibos</h1>
          <ul>
            <li>1. Choose "amiibo Emulator"</li>
            <li><img :src="require('@/assets/images/录数据1.png')"></li>
            <li>2. Long press "Main Menu"</li>
            <li><img :src="require('@/assets/images/录数据2.png')"></li>
            <li>3. Select "Create New Tag"</li>
            <li><img :src="require('@/assets/images/录数据3.png')"></li>
            <li>4. Input a name for the new bin</li>
            <li><img :src="require('@/assets/images/录数据4.png')"></li>
            <li>5. Select the new bin file just created</li>
            <li><img :src="require('@/assets/images/录数据5.png')"></li>
            <li>6. Put the device near the NFC detection area</li>
            <li><img :src="require('@/assets/images/录数据6.png')"></li>
            <li>7.  Click "Write" on the APP and place the phone over the device to write. The data will be added to the device automatically. And the tag will be re-named as the .BIN file name.</li>
            <li><img :src="require('@/assets/images/录数据7.png')"></li>
          </ul>

          <h1 id="chooseapp">App to Choose</h1>
          <p>Listed below are several options for both Android and iOS devices</p>
          <ul>
            <li>TagMo from Google Play Store or GitHub. Click <a href="https://github.com/HiddenRamblings/TagMo">here</a> to find the detailed instructions.</li>
            <li>Umiibo from Google Play Store or iOS APP Store. Video instructions can be found <a href="https://www.youtube.com/watch?v=Y7toV6R3lf0&ab_channel=Mr.Sujano">here</a>.</li>
            <li> Tagmiibo from iOS APP Store. Click <a href="https://apps.apple.com/us/app/tagmiibo-write-nfc-tags/id1578966288">here</a> to find FAQs.</li>
          </ul>

          <h1 id='keyandbinfile'>Encryption key and bin file</h1>
          <p>We are not able to provide information on the encryption key or amiibo bin files. However, it is very easy to find them on search engines and communities. You can email us if you cannot find the bin files.</p>

          <h1 id="blefiletransfer">BLE File Transfer</h1>
          <p>This application allows you to connect the iNFC or the pixl.js web page, to manage files on the device storage or update the firmware.</p>
          <ul>
            <li>1. Open the official web page, click "Device" Tab page</li>
            <li>2. Select "BLE File Transfer". The Bluetooth MAC address of device and official URL are shown at the screen. Stay on this page and make sure the screen is ON. There should be a flashing green light at the bottom, indicats that the device is ready to be connected via Bluetooth. </li>
            <li>3. Enable Bluetooth on your computer/iPhone and pair with Pixl.js on the device list.</li>
            <li>4. When successfully connected, you can manage the files easily or even upgrade the firmware.</li>
          </ul>

          <h1 id="firmwareupgrade">OTA Firmware Upgrade</h1>
          <p class="warning">
            An unsuccessful update has a chance of bricking your device. It's generally a good idea to avoid it unless there are critical features or fixes in the latest version that you absolutely need. If you decide to do it, proceed with the following steps at your own risk.
          </p>
          <p>nRF connect APP</p>
          <ul>
            <li>Install the nRF Connect application (you can find it on the both iOS and Android app stores).</li>
            <li>On the Devices List, select pixl.js (or pixl DFU) and tap the button CONNECT</li>
            <li>Put your pixl.js device on "Firmware Update" mode, then the device will enter DFU mode. To do so, select the "Settings" app and select the item "Firmware Update"</li>
            <li>Open "nRF Connect" app on your phone and connect to the device named pixl DFU to update the firmware.</li>
            <li>On iOS, The firmware is pixjs_ota_vxxx.zip in the compressed package and needs to be shared with the nrfconnect application.</li>
            <li>On Android you can use the DFU icon on the upper right of the screen, Select the Distribution packet (ZIP) option and browse your storage for the pixjs_ota_vxxx.zip file.</li>
            <li>All available firmware will be posted here: https://github.com/solosky/pixl.js/releases</li>
          </ul>

          <h1 id="acknowledgments">Acknowledgments</h1>
          <p>The hardware and firmware are powered by solosky's pixljs. This project would not be where it is today without the collective effort and dedication of this amazing community. Thank you all for your contributions!</p>

        </div>


      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      PDFUrl: '/public/files/UserManual.pdf'
    }
  }
}
</script>

<style>

#instruct-navi li{
  list-style: none;
}

#instruct-navi a{
  color: #FF00FF;
  text-decoration: none;
  font-size: 1.4em;
}

#manual {
  width: 600px;
}

#manual li{
  list-style: none;
}

#manual img{
  max-width: 40%;
}

.warning {
  color: #FF00FF;
}

.fixed-aside {
  position: fixed;
  top: 200px;
  right: 100px;
}
</style>